/*
 * @Author: Jerry
 * @Date: 2021-01-28 19:48:20
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 16:10:01
 * @Description: file content
 */
/**
 * 获取订单状态数据
 */
// export const orderStatusList = [
// 	{
// 		label: '待确认',
// 		value: 1
// 	},
// 	{
// 		label: '已取消',
// 		value: 2
// 	},
// 	{
// 		label: '待签约',
// 		value: 3
// 	},
// 	{
// 		label: '待付款',
// 		value: 4
// 	},
// 	{
// 		label: '待发货',
// 		value: 5
// 	},
// 	{
// 		label: '待收货',
// 		value: 6
// 	},
// 	{
// 		label: '已结单',
// 		value: 7
// 	},
// 	{
// 		label: '待确认退款',
// 		value: 8
// 	},
// 	{
// 		label: '已退款',
// 		value: 9
// 	},
// 	{
// 		label: '退款失败',
// 		value: 10
// 	},
// ]
/**
 * 获取订单状态数据
 * 1:订单确认，2：订单取消，3：待发货，4：待收货，5：收货审核中，6：收货审核失败，7：结单，8：交易已取消
 */

import dayjs from 'dayjs'
export const orderStatusList = [{
		label: '订单待确认',
		value: 1
	},
	{
		label: '订单取消',
		value: 2
	},
	{
		label: '待发货',
		value: 3
	},
	{
		label: '待收货',
		value: 4
	},
	{
		label: '签收单审核中',
		value: 5
	},
	{
		label: '签收单审核失败',
		value: 6
	},
	{
		label: '签收单审核通过',
		value: 7
	},
	{
		label: '交易取消',
		value: 8
	},
	{
		label: '交易完成',
		value: 9
	}
]

export const formatDate = (value) => {
	if (value == -1 || value == -1000) {
		return ''
	} else {
		let date = new Date(value);
		let y = date.getFullYear();
		let MM = date.getMonth() + 1;
		MM = MM < 10 ? "0" + MM : MM;
		let d = date.getDate();
		d = d < 10 ? "0" + d : d;
		let h = date.getHours();
		h = h < 10 ? "0" + h : h;
		let m = date.getMinutes();
		m = m < 10 ? "0" + m : m;
		let s = date.getSeconds();
		s = s < 10 ? "0" + s : s;
		return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
	}
}

export const matchingOrderStatus = (value) => {
	let result = value;
	for (let x = 0; x < orderStatusList.length; x++) {
		if (value == orderStatusList[x].value) {
			result = orderStatusList[x].label
		}
	}
	return result
}

export const freightType = ["", "途啦网络货运", "第三方物流", "快递"]

export const modeOfDelivery = [{
		value: 1,
		label: '送货上门'
	},
	{
		value: 2,
		label: '自提'
	}
]

export const matchingModeOfDelivery = (value) => {
	let result = value;
	for (let x = 0; x < modeOfDelivery.length; x++) {
		if (value == modeOfDelivery[x].value) {
			result = modeOfDelivery[x].label
		}
	}
	return result
}

export const dealCancelStatus = [{
		value: 1,
		label: '交易取消审核中'
	},
	{
		value: 2,
		label: '交易取消审核失败'
	},
	{
		value: 3,
		label: '交易取消审核通过'
	}
]

export const matching = (value, list) => {
	let result = value;
	for (let x = 0; x < list.length; x++) {
		if (value == list[x].value) {
			result = list[x].label
		}
	}
	return result
}

// 运单状态
export const formatStatus = (data) => {
	if (data === -1){
		return '取消接单'
	}
	if (data === 0){
		return '接单中'
	}
	if (data === 1){
		return '装货中'
	}
	if (data === 2){
		return '运输中'
	}
	if (data === 3){
		return '已交货'
	}
	if (data === 4){
		return '已签收'
	}
	if (data === 5){
		return '已回收'
	}
	if (data === 6){
		return '已作废'
	}
	return '接单中'
}

// 运单状态
export const formatPayStatus = (data) => {
	if (data === 1){
		return '核实中'
	}
	if (data === 2){
		return '已对账'
	}
	if (data === 3){
		return '已支付'
	}
	if (data === 4){
		return '已开票'
	}
	if (data === 5){
		return '支付中'
	}
	if (data === 6){
		return '支付失败'
	}
	return '核实中'
}

// 时间转化
export const dayJsFormat =(time)=> {
	console.log('time', time)
	return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
}
