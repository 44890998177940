<!--
 * @Author: Jerry
 * @Date: 2021-01-13 10:47:38
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 09:46:21
 * @Description: file content
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$store.dispatch("getShopSysConfig");
    this.$store.dispatch("getRegionSelect");
  }
}
</script>

<style lang="scss">
html,body,#app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
