/*
 * @Author: Jerry
 * @Date: 2021-02-25 12:18:15
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-25 18:16:16
 * @Description: file content
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

import './style/common.scss';

/* 图标 */
import './iconfont/iconfont.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 自定义主题颜色
import './style/theme/index.css'
Vue.use(ElementUI)

//商品轮播插件
import VueSplide from '@splidejs/vue-splide';
Vue.use( VueSplide );

import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);

// 定义全局请求方法
import { httpGet, httpPost } from '@/axios/axios';
Vue.prototype.$httpGet = httpGet;
Vue.prototype.$httpPost = httpPost;
import { formatDate } from '@/util/util'
Vue.prototype.$formatDate = formatDate;
import { matching } from '@/util/util'
Vue.prototype.$matching = matching;

// vue-ls的使用
import Storage from 'vue-ls';
let options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: "Gz4cuoav9bVd5xA2ETMQoYag7FctqzfM"
})

Vue.use(Storage, options);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')