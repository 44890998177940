import { httpGet, httpPost } from '@/axios/axios';

// 手机号密码登录
export const loginByPassword = (params) => httpGet({
  url: '/shop/shopuser/loginByPassword',
  params: params
});

// 手机号验证码登录
export const loginByMobileCode = (params) => httpGet({
  url: '/shop/shopuser/loginByMobileCode',
  params: params
});

// 用户登出
export const logout = () => httpGet({
  url:  '/user/logout',
  method: 'get'
})

// 刷新token
export const refreshToken = () => httpGet({
  url:  '/shop/user/refesh',
  method: 'post'
})

// 获取用户信息
export const getUserInfo = () => httpGet({
  url:  '/shop/user/getUserInfo',
  method: 'post'
});

// 获取手机验证码
export const getMobileCode = (mobile) => httpGet({
  url: '/shop/mobilecode/getMobileCode',
  params: {
    mobile: mobile,
  },
})

//验证是否正确
export const checkMobileCodeByKey = (code, key) => httpGet({
  url: '/shop/mobilecode/checkMobileCodeByKey',
  params: {
    code: code,
    key:key
  },
})

// 供应商、采购商注册
export const userRegister = (data) => httpPost({
  url: '/shop/shopuser/userRegister',
  data: data
});

//修改用户信息
export const updateUser = (data) => httpPost({
  url: '/shop/shopuser/updateUser',
  data: data,
});

//提交供应商个体户和企业认证
export const companyAuthentication = (params) => httpPost({
  url: '/shop/shopuser/companyAuthentication',
  params: params
});

//获取Access Token
export const getAccessToken = (params) => httpPost({
  // url: 'https://aip.baidubce.com/oauth/2.0/token',
  url: '/oauth/2.0/token',
  params: params,
});

//个体户和企业营业执照的信息获取
export const getCompanyAuthentication = (params) => httpPost({
  // url: 'https://aip.baidubce.com/rest/2.0/ocr/v1/business_license',
  url: '/rest/2.0/ocr/v1/business_license',
  params: params
});

// 营业执照文字识别
export const getBusinessLicenseORC = (imageUrl) => httpGet({
  url:  '/shop/shopuser/getBusinessLicenseORC',
  method: 'get',
  params: { imageUrl }
});

//个人认证
export const singleAuthentication = (data) => httpPost({
  url: '/shop/shopuser/singleAuthentication',
  data: data
});


// 根据Token获取当前登录用户信息
export const getLoginUserInfoByToken = () => httpGet({
  url:  '/shop/shopuser/getLoginUserInfoByToken',
  method: 'get'
});

//用户密码修改
export const updateUserPassword = (id, password) => httpPost({
  url:  '/shop/shopuser/updateUserPassword',
  data: {
    id: id,
    password: password
  }
});

//用户密码修改
export const forgetThePassword = (mobile, password) => httpPost({
  url:  '/shop/shopuser/forgetThePassword',
  data: {
    mobile: mobile,
    password: password
  }
});

//用户提现密码设置
export const updateUserWithdrawalPwd = (id, withdrawalPwd) => httpPost({
  url:  '/shop/shopuser/updateUserWithdrawalPwd',
  data: {
    id: id,
    withdrawalPwd: withdrawalPwd
  }
});

//校验提现密码是否正确
export const checkUserWithdrawalPwd = (userId, password) => httpGet({
  url:  '/shop/shopuser/checkUserWithdrawalPwd',
  params: {
    userId: userId,
    password: password
  }
});

//查询供应商金额提现记录
export const findUserDrawMoneyList = (params) => httpGet({
  url:  '/shop/shopuser/findUserDrawMoneyList',
  params: params
});

//新增供应商金额提现记录
export const createUserDrawMoney = (userId, money) => httpPost({
  url:  '/shop/shopuser/userDrawMoney',
  data: {
    userId: userId,
    money: money
  }
});

//查询提现的总金额
export const getUserDrawTotalMoney = (params) => httpGet({
  url:  '/shop/fund/userdrawmoney/getUserDrawTotalMoney',
  params: params
});

//新增用户的充值记录
export const createUserTopUpRecord = (userId, money,proof,id) => httpPost({
  url:  '/shop/shopuser/userTopUpRecord',
  data: {
    userId: userId,
    money: money,
    proof: proof,
    id
  }
});

//查询用户的充值-消费记录
export const findUserTopUpOrConsumeList = (params) => httpGet({
  url:  '/shop/shopuser/findUserTopUpOrConsumeList',
  params: params
});

//查询用户的收货地址列表
export const findUserAddressList = (userId) => httpGet({
  url:  '/shop/shopuser/findUserAddressList',
  params: {
    userId: userId
  }
});

// 新增-修改用户的收货地址
export const insertOrUpdateUserAddress = (data) => httpPost({
  url:  '/shop/shopuser/insertOrUpdateUserAddress',
  data: data
});

// 删除用户地址
export const deleteUserAddress = (addIds) => httpPost({
  url:  '/shop/shopuser/deleteUserAddress',
  params: {
    addIds: addIds
  }
});

// 查询用户默认收货地址
export const loadUserDefaultAddress = (userId) => httpGet({
  url:  '/shop/shopuser/loadUserDefaultAddress',
  params: {
    userId: userId
  }
});

// 获取省市数据
export const regionSelect = (data) => httpGet({
  url:  '/shop/blade-system/region/lazy-tree',
  data: data
});

// 购物车列表
export const myCartList = (data) => httpPost({
  url:  '/shop/shopingcar/shoppingcar/myCartList',
  data: data
});

// 添加到购物车
export const addCar = (data) => httpPost({
  url:  '/shop/shopingcar/shoppingcar/addCar',
  data: data
});

// 删除购物车
export const deleteCarRecord = (id) => httpPost({
  url:  '/shop/shopingcar/shoppingcar/deleteCarRecord',
  params: {
    id: id
  }
});

// 修改购物车商品数量
export const updateCarGoodsNumber = (data) => httpPost({
  url:  '/shop/shopingcar/shoppingcar/updateCarGoodsNumber',
  data: data
});

// 根据商品类型+搜索参数分页获取商品数
export const getShopPageDataByParam = (params) => httpGet({
  url:  '/shop/shopManage/getShopPageDataByParam',
  params: params
});

// 全文检索接口
export const getGoodsPageByFTS = (params) => httpGet({
  url:  '/shop/goods/goods/getGoodsPageByFTS',
  params: params
});

// 根据购物车ID获取购物车的信息列表
export const getMyCarDataList = (ids) => httpGet({
  url:  '/shop/shopingcar/shoppingcar/getMyCarDataList',
  params: {
    ids: ids
  }
});

// 买家下单
export const buyerCreateOrder = (data) => httpPost({
  url:  '/shop/buyerOrder/buyerCreateOrder',
  data: data
});

// 分页获取买家订单列表
export const getBuyerOrderPageBuy = (params) => httpGet({
  url:  '/shop/buyerOrder/getBuyerOrderPage',
  params: params
});

// 分页获取卖家订单列表
export const getBuyerOrderPageSell = (params) => httpGet({
  url:  '/shop/sellOrder/getSellOrderPage',
  params: params
});

// 分页获取采购商可开票的订单列表
export const getBuyerOpenInvoiceOrderPage = (params) => httpGet({
  url: '/shop/buyerOrder/getBuyerOpenInvoiceOrderPage',
  params: params
})

// 分页获取待开票订单列表
export const getBuyerOpenInvoiceRecordPage = (params) => httpGet({
  url: '/shop/buyerOrder/getBuyerOpenInvoiceRecordPage',
  params: params
})

// 分页获取采购商开票历史记录列表
export const getOrderInvoiceDataPage = (params) => httpGet({
  url: '/shop/order/orderinvoice/getOrderInvoiceDataPage',
  params: params
})

// 卖家确认订单
export const buyerAffirmOrder = (orderId) => httpGet({
  url:  '/shop/sellOrder/buyerAffirmOrder',
  params: {
    orderId: orderId
  }
});

// 卖家取消订单
export const buyerCancelOrder = (orderId) => httpGet({
  url:  '/shop/sellOrder/buyerCancelOrder',
  params: {
    orderId: orderId
  }
});

// 买家取消订单
export const buyerCancelOrderBuyer = (orderId) => httpGet({
  url:  '/shop/buyerOrder/buyerCancelOrder',
  params: {
    orderId: orderId
  }
});

// 根据商品类型查询属性以及属性的检索数据
export const getTypePropertySearchParam = (typeId) => httpGet({
  url:  '/shop/shopManage/getTypePropertySearchParam',
  params: {
    typeId: typeId
  }
});

// 校验手机是否存在系统中
export const checkMobileInSys = (mobile) => httpGet({
  url:  '/shop/pcwxlogin/checkMobileInSys',
  params: {
    mobile: mobile
  }
});

// 账号密码登录时校验是否有账号和账号密码是否正确
export const checkUserMobilePwd = (mobile, password) => httpGet({
  url:  '/shop/pcwxlogin/checkUserMobilePwd',
  params: {
    mobile,
    password
  }
});

// 令牌登录
export const loginTicket = (params) => httpGet({
  url:  '/shop/pcwxlogin/loginTicket',
  params: params
});

// 绑定公众号
export const wechatBind = (params) => httpGet({
  url:  '/shop/wechatcenter/wechatBind',
  params: params
});

// 绑定工作人员
export const wechatBindSystem = (params) => httpGet({
  url:  '/shop/wechatcenter/wechatBindSystem',
  params: params
});

// 获取认证结果
export const getVerifyResult = (params) => httpGet({
  url:  '/shop/faceid/identity/getVerifyResult',
  params: params
});

//获取验证码
export const getCaptcha = () => httpGet({
  url: '/shop/blade-auth/captcha',
});

// 卖家上传发票
export const uploadSellerInvoice = (data) => httpPost({
  url:  '/shop/order/order/uploadSellerInvoice',
  data: data
});

// 获取卖家发票信息
export const getSellerInvoice = (params) => httpGet({
  url: '/shop/order/order/getSellerInvoice',
  params: params
});
