import axios from 'axios'
import {
  Base64
} from 'js-base64';
import {
  getToken
} from '@/util/auth'
import router from '@/router'
import store from '@/store/';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  Message
} from 'element-ui'

let website = {
  clientId: 'saber', // 客户端id
  clientSecret: 'saber_secret', // 客户端密钥
}

const service = axios.create({
  validateStatus(status) {
    return status >= 200 && status < 504 // 设置默认的合法的状态
  },
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 30000, // 请求 30s 超时
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
      // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    }
  },
})

service.defaults.retry = 3 // 请求重试次数
service.defaults.retryDelay = 1000 // 请求重试时间间隔
service.defaults.shouldRetry = true // 是否重试

NProgress.configure({
  showSpinner: false
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    NProgress.start() // start progress bar
    const meta = (config.meta || {});
    const isToken = meta.isToken === false;
    config.headers['Authorization'] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`;
    // 每次发送请求之前判断是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况，此处token一般是用户完成登录后储存到localstorage里的
    if (getToken() && !isToken) {
      config.headers['Blade-Auth'] = 'bearer ' + getToken() // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
    }
    return config
  },
  error => {
    return Promise.error(error)
  })


// 响应拦截器
service.interceptors.response.use(response => {
  NProgress.done();
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  if (response.status === 200) {
    if (response.data.code === 511) {
      // 未授权调取授权接口
    } else if (response.data.code === 401) {
      // 未登录跳转登录页
      Message({
        message: '登录信息失效，请重新登陆。',
        type: 'error'
      })
      let redirect = router.history.current.name;
      let query = JSON.stringify(router.history.current.query);
      setTimeout(() => {
        store.dispatch('LogOut').then(() => {
          window.location.replace(`/userLogin?redirect=${redirect}&query=${query}`)
          // router.push({
          //   name: 'UserLogin',
          //   query: {
          //     'redirect': redirect,
          //     'query': query
          //   }
          // })
        });
      }, 1500)
    } else {
      return Promise.resolve(response)
    }
  } else {
    return Promise.reject(response)
  }
}, error => {
  // 我们可以在这里对异常状态作统一处理
  if (error.response.status) {
    // 处理请求失败的情况
    // 对不同返回码对相应处理
    return Promise.reject(error.response)
  }
})

// get 请求
export function httpGet({
  url,
  params = {}
}) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params,
      // paramsSerializer: function (par) {
      //   return qs.stringify(par, {arrayFormat: 'indices'})
      // }
    }).then((res) => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

// post
// post请求
export function httpPost({
  url,
  data = {},
  params = {}
}) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'post',
      // transformRequest: [function (data) {
      //   let ret = ''
      //   for (let it in data) {
      //     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      //   }
      //   return ret
      // }],
      // 发送的数据
      data,
      // url参数
      params

    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}