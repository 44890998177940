/*
 * @Author: Jerry
 * @Date: 2021-01-29 18:00:29
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 16:14:46
 * @Description: file content
 */
import { httpGet } from '@/axios/axios';

// 首页信息
export const shopIndexData = (rows) => httpGet({
  url: '/shop/shopManage/shopIndexData',
  params: {
    rows: rows
  }
});

// 首页banner
export const getShopIndexBanner = () => httpGet({
  url: '/shop/shopManage/getShopIndexBanner'
});

// 获取类目
export const getGoodsType = () => httpGet({
  url: '/shop/goods/goodstype/list',
})

// 获取网站基本信息
export const getShopSysConfig = () => httpGet({
  url: '/shop/shopManage/getShopSysConfig',
})

// 获取商品详细信息
export const getShopDetailInfo = (id) => httpGet({
  url: '/shop/shopManage/getShopDetailInfo',
  params: {
    goodsId: id
  }
})

// 城-获取卖家非正式销售商品详细信息
export const getSellShopDetailInfo = (goodsNo) => httpGet({
  url: '/shop/shopManage/getSellShopDetailInfo',
  params: {
    goodsNo: goodsNo
  }
})

// 获取系统公告信息
export const getSystemNotice = (row) => httpGet({
  url: '/shop/shopManage/getSystemNotice',
  params: {
    row: row
  }
})