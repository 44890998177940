import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: "/WXbinding",
    name: "WXbinding",
    component: resolve => (require(['../views/WXbinding'], resolve)),
  },
  {
    path: "/SYSbinding",
    name: "SYSbinding",
    component: resolve => (require(['../views/SYSbinding'], resolve)),
  },
  {
    path: "/authenticationSuccess",
    name: "SuthenticationSuccess",
    component: resolve => (require(['../views/authenticationSuccess'], resolve)),
  },
  //一个url配置一个组件

  // 默认页面 - 首页
  {
    path: "/index",
    name: 'index',
    component: resolve => (require(['../views/Home'], resolve)),
    // component: resolve=>(require(['../views/purchaser/goodBrowse/Home'],resolve)),
  },
  // 更多商品页面
  {
    path: "/moreGoods",
    // component: resolve=>(require(['../views/purchaser/goodBrowse/MoreGoods'],resolve)),
    name: 'MoreGoods',
    component: resolve => (require(['../views/MoreGoods'], resolve)),
  },
  // 全文检索商品列表
  {
    path: "/FTSGoods",
    name: 'FTSGoods',
    component: resolve => (require(['../views/FTSGoods'], resolve)),
  },
  // 商品详情页
  {
    path: "/gooddetail",
    // component: resolve=>(require(['../views/purchaser/goodBrowse/GoodDetail'],resolve)),
    name: 'GoodDetail',
    component: resolve => (require(['../views/GoodDetail'], resolve)),
  },
  // 订单结算页面
  {
    path: "/orderSettlement",
    name: 'OrderSettlement',
    component: resolve => (require(['../views/order/OrderSettlement'], resolve)),
  },

  //订单详情页面
  {
    path: "/orderDetail",
    component: resolve => (require(['../views/order/OrderDetail'], resolve)),
    name: 'OrderDetail',
  },

  //申请退款
  {
    path: "/orderRefundModal",
    component: resolve => (require(['../components/myOrder/orderRefundModal.vue'], resolve)),
    name: 'OrderRefundModal',
  },


  /* 首页 */
  {
    path: "/",
    redirect: "/index"
  },
  // 登录时选择角色页面
  {
    path: "/roleChoose",
    name: 'RoleChoose',
    component: resolve => (require(['../views/Login/RoleChoose'], resolve))
  },
  // 订单共享与安全
  {
    path: "/SharingAndSecurity",
    name: 'SharingAndSecurity',
    component: resolve => (require(['../views/Login/SharingAndSecurity'], resolve))
  },
  // 注销须知
  {
    path: "/CancellationNotice",
    name: 'CancellationNotice',
    component: resolve => (require(['../views/Login/CancellationNotice'], resolve))
  },
  // 账号密码登录页面
  {
    path: "/userLogin",
    name: 'UserLogin',
    component: resolve => (require(['../views/Login/UserLogin'], resolve))
  },
  // 验证码登录页面
  {
    path: "/codeLogin",
    name: 'CodeLogin',
    component: resolve => (require(['../views/Login/CodeLogin'], resolve))
  },
  // 微信扫一扫登录页面
  {
    path: "/wechatLogin",
    name: 'WechatLogin',
    component: resolve => (require(['../views/Login/WechatLogin'], resolve))
  },
  // 注册页面 - 采购商
  {
    path: "/purchaser/register",
    component: resolve => (require(['../views/Login/purchaser/Register'], resolve)),
    name: 'PurchaserRegister'
  },
  // 注册页面 - 供应商
  {
    path: "/supplier/register",
    component: resolve => (require(['../views/Login/supplier/Register'], resolve)),
    name: 'SupplierRegister'
  },
  // //密码登录页面 - 采购商
  // {
  //   path: "/purchaser/pwdLogin",
  //   component: resolve=>(require(['../views/Login/purchaser/PwdLogin'],resolve)),
  //   name: 'PurchaserPwdLogin'
  // },
  // //手机验证码登录页面 - 采购商
  // {
  //   path: "/purchaser/verifyLogin",
  //   component: resolve=>(require(['../views/Login/purchaser/VerifyLogin'],resolve)),
  //   name: 'PurchaserVerifyLogin'
  // },
  // //微信扫码登录页面 - 采购商
  // {
  //   path: "/purchaser/WeChatLogin",
  //   component: resolve=>(require(['../views/Login/purchaser/WeChatLogin'],resolve)),
  //   name: 'WeChatLogin'
  // },
  // // 密码登录页面 - 供应商
  // {
  //   path: "/supplier/pwdLogin",
  //   component: resolve=>(require(['../views/Login/supplier/PwdLogin'],resolve)),
  //   name: 'SupplierPwdLogin'
  // },
  // // 手机验证码登录页面 - 供应商
  // {
  //   path: "/supplier/verifyLogin",
  //   component: resolve=>(require(['../views/Login/supplier/VerifyLogin'],resolve)),
  //   name: 'SupplierVerifyLogin'
  // },


  /* 采购商 */
  // 购物车页面
  {
    path: "/purchaser/orderSubmite/cart",
    component: resolve => (require(['../views/purchaser/orderSubmite/ShopCart'], resolve)),
    name: 'ShopCart',
  },
  // 提交订单页面
  {
    path: "/purchaser/orderSubmite/orderSubmit",
    component: resolve => (require(['../views/purchaser/orderSubmite/OrderSubmit'], resolve)),
    name: 'OrderSubmit',
  },
  //订单提交成功页面
  {
    path: "/purchaser/orderSubmite/orderSuccess",
    component: resolve => (require(['../views/purchaser/orderSubmite/OrderSuccess'], resolve)),
    name: 'OrderSuccess',
  },
  // 支付成功页面
  {
    path: "/purchaser/personalCenter/paySuccess",
    component: resolve => (require(['../views/purchaser/personalCenter/order/PaySuccess'], resolve)),
    name: 'PaySuccess',
  },



  /* 供应商 */

  //供应商采购商个人中心页面
  {
    path: "/SupPurHome",
    component: resolve => (require(['../views/SupPurHome.vue'], resolve)),
    name: 'SupPurHome',
    children: [
      //供应商采购商密码重置
      // 我的账户新密码填写页面
      {
        path: '/supplier/supplierHome/writerPwd',
        name: 'WriterPwd',
        component: resolve => (require(['../views/supplier/InformationManagement/userPwdChange/WriterPwd.vue'], resolve)),
      },
      //验证码获取页面
      {
        path: '/supplier/supplierHome/getVerfiy',
        name: 'GetVerfiy',
        component: resolve => (require(['../views/supplier/InformationManagement/userPwdChange/GetVerfiy.vue'], resolve)),
      },
      //手机验证
      {
        path: '/supplier/supplierHome/phoneVerfiy',
        name: 'PhoneVerfiy',
        component: resolve => (require(['../views/supplier/InformationManagement/userPwdChange/PhoneVerfiy.vue'], resolve)),
      },
      //重置成功
      {
        path: '/supplier/supplierHome/resetSuccess',
        name: 'ResetSuccess',
        component: resolve => (require(['../views/supplier/InformationManagement/userPwdChange/ResetSuccess.vue'], resolve)),
      },


      /* 供应商的个人信息 */
      // 账号认证页面
      {
        path: '/supplier/authentication',
        name: 'Authentication',
        component: resolve => (require(['../views/supplier/InformationManagement/certification/Authentication.vue'], resolve)),
      },
      // //个人账号认证
      // {
      //   path:'/supplier/supplierHome/personal',
      //   name:'Personal',
      //   component:resolve=>(require(['../views/supplier/InformationManagement/certification/Personal.vue'],resolve)),
      // },
      // //个体户账号认证
      // {
      //   path:'/supplier/supplierHome/soho',
      //   name:'Soho',
      //   component:resolve=>(require(['../views/supplier/InformationManagement/certification/Soho.vue'],resolve)),
      // },
      // //企业账号认证
      // {
      //   path:'/supplier/supplierHome/enterprise',
      //   name:'Enterprise',
      //   component:resolve=>(require(['../views/supplier/InformationManagement/certification/Enterprise.vue'],resolve)),
      // },
      //会员信息
      {
        path: '/supplier/supplierHome/information',
        name: 'Information',
        component: resolve => (require(['../views/supplier/InformationManagement/personalInformation/Information.vue'], resolve)),
      },
      //我的账户
      /* 我的钱包 */
      {
        path: '/supplier/supplierHome/myAccount',
        name: 'MyAccount',
        component: resolve => (require(['../views/supplier/myAccount/MyAccount.vue'], resolve)),
      },
      //密码重置
      {
        path: '/supplier/supplierHome/depositPwdChange',
        name: 'DepositPwdChange',
        component: resolve => (require(['../views/supplier/myAccount/DepositPwdChange.vue'], resolve)),
      },
      //提现记录
      {
        path: '/supplier/supplierHome/withdrawalRecord',
        name: 'WithdrawalRecord',
        component: resolve => (require(['../views/supplier/myAccount/WithdrawalRecord.vue'], resolve)),
        children: [
          //全部记录
          {
            path: '/supplier/supplierHome/depositPwdChange/allRecord',
            name: 'AllRecord',
            component: resolve => (require(['../views/supplier/myAccount/WithdrawalRecord/allRecord.vue'], resolve)),
          },
          //审核中
          {
            path: '/supplier/supplierHome/depositPwdChange/audit',
            name: 'Audit',
            component: resolve => (require(['../views/supplier/myAccount/WithdrawalRecord/audit.vue'], resolve)),
          },
          //提现中
          {
            path: '/supplier/supplierHome/depositPwdChange/WithdrawalOf',
            name: 'WithdrawalOf',
            component: resolve => (require(['../views/supplier/myAccount/WithdrawalRecord/WithdrawalOf.vue'], resolve)),
          },
          //已到账
          {
            path: '/supplier/supplierHome/depositPwdChange/toAccount',
            name: 'ToAccount',
            component: resolve => (require(['../views/supplier/myAccount/WithdrawalRecord/toAccount.vue'], resolve)),
          },
          //提现失败
          {
            path: '/supplier/supplierHome/depositPwdChange/withdrawalFailure',
            name: 'WithdrawalFailure',
            component: resolve => (require(['../views/supplier/myAccount/WithdrawalRecord/withdrawalFailure.vue'], resolve)),
          },
        ],
        redirect: '/supplier/supplierHome/depositPwdChange/allRecord'
      },
      //订单信息
      //我的订单
      {
        path: '/myOrder',
        redirect: '/myOrder/AllOrders',
        name: 'MyOrder',
        component: resolve => (require(['../views/supplier/orderManagement/MyOrder.vue'], resolve)),
        children: [
          //全部订单
          {
            path: '/myOrder/AllOrders',
            name: 'AllOrders',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/AllOrders.vue'], resolve)),
          },
          //订单确定
          {
            path: '/myOrder/OrderDetermine',
            name: 'OrderDetermine',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/OrderDetermine.vue'], resolve)),
          },
          //待签约
          {
            path: '/myOrder/ToSignUp',
            name: 'ToSignUp',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/ToSignUp.vue'], resolve)),
          },
          //待开票
          {
            path: '/myOrder/WaitInvoice',
            name: 'WaitInvoice',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/WaitInvoice.vue'], resolve)),
          },
          //待付款
          {
            path: '/myOrder/WaitPayment',
            name: 'WaitPayment',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/WaitPayment.vue'], resolve)),
          },
          //待发货
          {
            path: '/myOrder/WaitSendGoods',
            name: 'WaitSendGoods',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/WaitSendGoods.vue'], resolve)),
          },
          //待收货
          {
            path: '/myOrder/WaitReceived',
            name: 'WaitReceived',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/WaitReceived.vue'], resolve)),
          },
          //已结算
          {
            path: '/myOrder/HaveSettled',
            name: 'HaveSettled',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/HaveSettled.vue'], resolve)),
          },
          //已退款
          {
            path: '/myOrder/RefundManagement',
            name: 'RefundManagement',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/RefundManagement.vue'], resolve)),
          },
          //交易取消
          {
            path: '/myOrder/TransactionCancel',
            name: 'TransactionCancel',
            component: resolve => (require(['../views/supplier/orderManagement/MyOrder/TransactionCancel.vue'], resolve)),
          }

        ]
      },
      //待发货
      {
        path: '/supplier/supplierHome/waitDelivery',
        name: 'WaitDelivery',
        component: resolve => (require(['../views/supplier/orderManagement/WaitDelivery.vue'], resolve)),
      },
      //历史开票信息
      {
        path: '/supplier/supplierHome/historyInvoice',
        name: 'HistoryInvoice',
        component: resolve => (require(['../views/supplier/historyInvoice/historyInvoice.vue'], resolve)),
      },
      //信息管理
      /* 商品列表 */
      {
        path: '/supplier/supplierHome/goodsList',
        name: 'GoodsList',
        component: resolve => (require(['../views/supplier/commodityManagement/GoodsList.vue'], resolve)),
      },
      /* 商品管理2 */
      {
        path: '/supplier/supplierHome/basicInformation',
        name: 'BasicInformation',
        component: resolve => (require(['../views/supplier/commodityManagement/BasicInformation.vue'], resolve)),
      },
      /* 商品管理1 */
      {
        path: '/supplier/supplierHome/goodsReleased',
        name: 'GoodsReleased',
        component: resolve => (require(['../views/supplier/commodityManagement/GoodsReleased.vue'], resolve)),
      },
      /* 商品管理3 */
      {
        path: '/supplier/supplierHome/graphicDescription',
        name: 'GraphicDescription',
        component: resolve => (require(['../views/supplier/commodityManagement/GraphicDescription.vue'], resolve)),
      },

      /* 采购商个人中心页面 */
      // 账户认证
      /* {
        path:'/purchaser/purchaserHome/Certification',
        name:'PurchaserCertification',
        component:resolve=>(require(['../views/purchaser/personalCenter/personalManagement/certification/Certification.vue'],resolve)),
      }, */
      // 我的账户
      //消费记录
      {
        path: '/purchaser/purchaserHome/Expense',
        name: 'PurchaserExpense',
        component: resolve => (require(['../views/purchaser/personalCenter/personalManagement/myAccount/Expense.vue'], resolve)),
      },
      //我的钱包
      {
        path: '/purchaser/purchaserHome/myMoney',
        name: 'PurchaserMyMoney',
        component: resolve => (require(['../views/purchaser/personalCenter/personalManagement/myAccount/myMoney.vue'], resolve)),
      },
      //充值记录
      {
        path: '/purchaser/purchaserHome/Recharge',
        name: 'PurchaserRecharge',
        component: resolve => (require(['../views/purchaser/personalCenter/personalManagement/myAccount/Recharge.vue'], resolve)),
      },


      //个人信息
      /*  {
         path:'/purchaser/purchaserHome/PersonalInfomation',
         name:'PurchaserPersonal',
         component:resolve=>(require(['../views/purchaser/personalCenter/personalManagement/PersonalInfomation.vue'],resolve)),
       }, */

      //收货地址
      {
        path: '/purchaser/purchaserHome/ShipAddr',
        name: 'PurchaserShipAddr',
        component: resolve => (require(['../views/purchaser/personalCenter/personalManagement/ShipAddr.vue'], resolve)),
      },
      //我的订单
      // {
      //   path:'/purchaser/purchaserHome/myOrder',
      //   name:'PurchaserMyOrder',
      //   component:resolve=>(require(['../views/purchaser/personalCenter/order/MyOrder.vue'],resolve)),
      // },
      //退货
      {
        path: '/purchaser/purchaserHome/ReturnGoods',
        name: 'ReturnGoods',
        component: resolve => (require(['../views/purchaser/personalCenter/order/ReturnGoods.vue'], resolve)),
      },
      //订单支付
      {
        path: '/purchaser/purchaserHome/OrderPay',
        name: 'OrderPay',
        component: resolve => (require(['../views/purchaser/personalCenter/order/OrderPay.vue'], resolve)),
      },



    ]
  },


]
const router = new VueRouter({
  mode: 'history', //去掉url中的#
  //配置路由和组件之间的映射关系
  routes,
})

export default router
//将router对象传入到vue实例中
