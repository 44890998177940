import {
  setStore,
  getStore
} from '@/util/store'
import {
  getShopSysConfig
} from '@/api/website'

const website = {
  state: {
    websiteInfo: getStore({
      name: 'websiteInfo'
    }) || {}
  },
  actions: {
    // 根据Token获取当前登录用户信息
    getShopSysConfig({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getShopSysConfig().then((res) => {
          const data = res.data;
          commit('SET_WEBSITE_INFO', data);
          resolve(data);
        }).catch(err => {
          reject(err);
        })
      })
    },
  },
  mutations: {
    SET_WEBSITE_INFO: (state, userInfo) => {
      state.websiteInfo = userInfo;
      setStore({
        name: 'websiteInfo',
        content: state.getShopSysConfig
      })
    }
  }

}
export default website