import {
  setToken,
  getToken,
  removeToken
} from '@/util/auth'
import {
  setStore,
  getStore
} from '@/util/store'
import {
  validatenull
} from '@/util/validate'

import {
  myCartList
} from '@/api/user'

import {
  loginByPassword,
  loginByMobileCode,
  getUserInfo,
  refreshToken,
  getLoginUserInfoByToken,
  regionSelect,
  loginTicket
} from '@/api/user'

const user = {
  state: {
    userInfo: getStore({
      name: 'userInfo'
    }) || {},
    token: getToken() || '',
    regionSelect: getStore({
      name: 'regionSelect'
    }) || [],
    shopCarInfo: getStore({
      name: 'shopCarInfo'
    }) || {},
  },
  actions: {
    //根据用密码登录
    loginByPassword({
      dispatch,
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByPassword(userInfo).then(res => {
          if (res.success) {
            const data = res.data;
            commit('SET_TOKEN', data.accessToken);
            commit('SET_USER_INFO', data.userInfo);
            setTimeout(() => {
              dispatch("myCartList", data.userInfo.id);
            }, 1000)
          }
          resolve(res);
        }).catch(error => {
          reject(error);
        })
      })
    },
    //根据手机验证码登录
    loginByMobileCode({
      dispatch,
      commit
    }, userInfo) {
      return new Promise((resolve) => {
        loginByMobileCode(userInfo).then(res => {
          if (res.success) {
            const data = res.data;
            commit('SET_TOKEN', data.accessToken);
            commit('SET_USER_INFO', data.userInfo);
            setTimeout(() => {
              dispatch("myCartList", data.userInfo.id);
            }, 1000)
          }
          resolve(res);
        })
      })
    },
    //令牌登录
    loginTicket({
      dispatch,
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        loginTicket(userInfo).then(res => {
          if (res.success) {
            const data = res.data;
            commit('SET_TOKEN', data.accessToken);
            commit('SET_USER_INFO', data.userInfo);
            setTimeout(() => {
              dispatch("myCartList", data.userInfo.id);
            }, 1000)
          }
          resolve(res);
        }).catch(error => {
          reject(error);
        })
      })
    },
    // 获取用户信息
    GetUserInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((res) => {
          const data = res.data;
          commit('SET_USER_INFO', data);
          resolve(data);
        }).catch(err => {
          reject(err);
        })
      })
    },
    //刷新token
    RefreshToken({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken).then(res => {
          const data = res.data;
          commit('SET_TOKEN', data);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 根据Token获取当前登录用户信息
    GetLoginUserInfoByToken({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getLoginUserInfoByToken().then((res) => {
          const data = res.data;
          commit('SET_USER_INFO', data);
          resolve(data);
        }).catch(err => {
          reject(err);
        })
      })
    },
    // 登出
    LogOut({
      commit
    }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        commit('SET_USER_INFO', {});
        commit('SET_MENU', [])
        commit('SET_MENU_ALL', []);
        commit('SET_ROLES', []);
        removeToken()
        resolve()
      })
    },
    // 获取省市级级联数据
    getRegionSelect({
      commit
    }) {
      return new Promise((resolve) => {
        let result = getStore({
          name: 'regionSelect'
        })
        if (result) {
          commit('SET_REGION', result);
          resolve(result);
        } else {
          regionSelect().then((res) => {
            let data = res.data;
            commit('SET_REGION', data);
            resolve(data);
          });
        }
      })
    },
    // 根据用户ID获取购物车数据
    myCartList({
      commit
    }, userId) {
      return new Promise((resolve, reject) => {
        myCartList({
          userId: userId,
          current: 1,
          size: 10
        }).then((res) => {
          const data = res.data;
          commit('SET_SHOPCAR_INFO', data);
          resolve(data);
        }).catch(err => {
          reject(err);
        })
      })
    },
  },
  mutations: {
    SET_SHOPCAR_INFO: (state, info) => {
      state.shopCarInfo = info;
      setStore({
        name: 'shopCarInfo',
        content: state.shopCarInfo
      })
    },
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token;
      setStore({
        name: 'token',
        content: state.token,
        type: 'session'
      })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStore({
        name: 'userInfo',
        content: state.userInfo
      })
    },
    SET_REGION: (state, regionSelect) => {
      state.regionSelect = regionSelect;
      setStore({
        name: 'regionSelect',
        content: state.regionSelect
      })
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll
      setStore({
        name: 'menuAll',
        content: state.menuAll,
        type: 'session'
      })
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({
        name: 'menu',
        content: state.menu,
        type: 'session'
      })
      if (validatenull(menu)) return;
      //合并动态路由去重
      let menuAll = state.menuAll;
      menuAll = menuAll.concat(menu).reverse();
      let newMenu = [];
      for (let item1 of menuAll) {
        let flag = true;
        for (let item2 of newMenu) {
          if (item1.name === item2.name || item1.path === item2.path) {
            flag = false;
          }
        }
        if (flag) newMenu.push(item1);
      }
      state.menuAll = newMenu;
      setStore({
        name: 'menuAll',
        content: state.menuAll,
        type: 'session'
      })
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSION: (state, permission) => {
      let result = [];

      function getCode(list) {
        list.forEach(ele => {
          if (typeof (ele) === 'object') {
            const chiildren = ele.children;
            const code = ele.code;
            if (chiildren) {
              getCode(chiildren)
            } else {
              result.push(code);
            }
          }
        })
      }

      getCode(permission);
      state.permission = {};
      result.forEach(ele => {
        state.permission[ele] = true;
      });
      setStore({
        name: 'permission',
        content: state.permission,
        type: 'session'
      })
    }
  }

}
export default user